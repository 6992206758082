<template>
  <slot />
</template>

<script setup lang="ts">
import { TYPE } from 'vue-toastification';
import type { CartActionType } from '~/stores/cartStore/model';
import { useCartStore } from '~/stores/cartStore/cartStore';

const { $textDictionary } = useNuxtApp();
const cartStore = useCartStore();
const toasts = useShowToast();

const actionLabelMap = computed<Partial<Record<CartActionType, string>>>(
  () => ({
    addItems: $textDictionary.value['pdp.addToCart.success.message'],
    removeItem: $textDictionary.value['pdp.removeFromCart.success.message']
  })
);

watch(
  () => cartStore.lastAction,
  () => {
    if (!cartStore.lastAction) {
      return;
    }

    const error = cartStore.lastAction.error;
    if (error) {
      const message = Array.isArray(error) ? error.join('\n') : error;

      toasts.showToast(message, {
        type: TYPE.ERROR
      });

      return;
    }

    const message = actionLabelMap.value[cartStore.lastAction.type];
    if (message) {
      toasts.showToast(message, {
        type: TYPE.SUCCESS
      });
    }
  }
);
</script>
